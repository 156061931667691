// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-brands-js": () => import("./../../../src/templates/brands.js" /* webpackChunkName: "component---src-templates-brands-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/contact-us.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-legal-pages-js": () => import("./../../../src/templates/legal-pages.js" /* webpackChunkName: "component---src-templates-legal-pages-js" */),
  "component---src-templates-maintenance-js": () => import("./../../../src/templates/maintenance.js" /* webpackChunkName: "component---src-templates-maintenance-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */)
}

